body {
    font-family: "Inter", sans-serif;
    @apply bg-[#F6F7F9]
}
.bg-main-section{
    background-image: url(/src/Components/Assets/main-sec-bg.png);
}

.custom-width {
    @apply w-[calc(100%-210px)]
}

.custom-table {
    @apply w-[calc(100%-63px)]  mx-auto overflow-y-hidden border
    
}

.custom-table table {
    @apply border-collapse max-h-screen  overflow-y-auto w-full
}

.custom-table th, .custom-table td {
    @apply border p-2 text-left
}
.sb-avatar__text{
    background-color: #403F3F !important;
    border-radius: 5px;
    
}
.sb-avatar__text span{
    font-size: 14px;
}
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.tiptap {
    @apply focus-visible:outline-none min-h-[200px]
}